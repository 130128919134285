const CLIENT_ID = '793704673701-p2eskhjcm012dok9l3li8gltd75kpikn.apps.googleusercontent.com';
const API_KEY = 'AIzaSyClnGcL29WB-ObgkLaIcufwH-JwA08PvKU';

// Discovery doc URL for APIs used by the quickstart
const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';
const SCOPES = 'https://www.googleapis.com/auth/calendar';

let tokenClient;
let gapiInited = false;
let gisInited = false;
let responseFromCal;

// document.getElementById('authorize_button').style.visibility = 'hidden';
//       document.getElementById('signout_button').style.visibility = 'hidden';

function gapiLoaded() {
    gapi.load('client', initializeGapiClient);
}

/**
 * Callback after the API client is loaded. Loads the
 * discovery doc to initialize the API.
 */
async function initializeGapiClient() {
    await gapi.client.init({
        apiKey: API_KEY,
        discoveryDocs: [DISCOVERY_DOC],
    });
    gapiInited = true;
    maybeEnableButtons();
}

/**
 * Callback after Google Identity Services are loaded.
 */
function gisLoaded() {
    tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        callback: '', // defined later
    });
    gisInited = true;
    maybeEnableButtons();
}

/**
 * Enables user interaction after all libraries are loaded.
 */
function maybeEnableButtons() {
    if (gapiInited && gisInited) {
        // document.getElementById('authorize_button').style.visibility = 'visible';
    }
}

/**
       *  Sign in the user upon button click.
       */
function handleAuthClick() {
    tokenClient.callback = async (resp) => {
        if (resp.error !== undefined) {
            throw (resp);
        }
        document.getElementById('signout_button').style.visibility = 'visible';
        document.getElementById('authorize_button').innerText = 'Refresh';
        await authSuccess() 
        // listUpcomingEvents();
    };
    let gCalTokel = sessionStorage.getItem('gCalToken');
    if (gapi.client.getToken() === null && gCalTokel == null) {
        // Prompt the user to select a Google Account and ask for consent to share their data
        // when establishing a new session.
        tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
        // Skip display of account chooser and consent dialog for an existing session.
        tokenClient.requestAccessToken({ prompt: '' });
    }
}

function fetchManually() {
    // listUpcomingEvents();
}

/**
 *  Sign out the user upon button click.
 */
function handleSignoutClick() {
    const token = gapi.client.getToken();
    if (token !== null) {
        google.accounts.oauth2.revoke(token.access_token);
        gapi.client.setToken('');
        sessionStorage.removeItem('gCalToken')
        document.getElementById('content').innerText = '';
        document.getElementById('authorize_button').innerText = 'Authorize';
        document.getElementById('signout_button').style.visibility = 'hidden';
    }
}

/**
 * Print the summary and start datetime/date of the next ten events in
 * the authorized user's calendar. If no events are found an
 * appropriate message is printed.
 */
async function listUpcomingEvents() {
    if (gapi.client.getToken()) {
        sessionStorage.setItem('gCalToken', gapi.client.getToken());
    }
    authSuccess();
    let response;
    try {
        const request = {
            'calendarId': 'primary',
            'timeMin': (new Date(1717201975000)).toISOString(),
            'timeMax': (new Date(1719750775000)).toISOString(),
            'showDeleted': false,
            'singleEvents': true,
            'maxResults': 10,
            'orderBy': 'startTime',
        };
        response = await gapi.client.calendar.events.list(request);
    } catch (err) {
        document.getElementById('content').innerText = err.message;
        return;
    }

    const events = response.result.items;
    if (!events || events.length == 0) {
        document.getElementById('content').innerText = 'No events found.';
        return;
    }
    // Flatten to string to display
    const output = events.reduce(
        (str, event) => `${str}${event.summary} (${event.start.dateTime || event.start.date})\n`,
        'Events:\n');
    document.getElementById('content').innerText = output;
}

function authSuccess() {
    var externEvent = new Event('authSuccess');
    document.dispatchEvent(externEvent);
}

async function exportMonthEvents(eventsData) {
console.log('exporting');
addMultiple(eventsData)
}


async function scheduleEvents(data) {
    var event = {
        'summary': data.name,
        'location': data?.addressLine1 + '' + data?.addressLine2,
        'description': 'A chance to hear more about Google\'s developer products.',
        'start': {
            'dateTime': new Date(data.startDateTime),
            'timeZone': 'America/Los_Angeles'
        },
        'end': {
            'dateTime': new Date(data.endDateTime),
            'timeZone': 'America/Los_Angeles'
        },
        'recurrence': [
        ],
        'attendees': [
        ],
        'reminders': {
            'useDefault': false,
            'overrides': [
            ]
        }
    };

    var request = gapi.client.calendar.events.insert({
        'calendarId': 'primary',
        'resource': event
    });

    const res = await request.execute(function (event) {

        // appendPre('Event created: ' + event.htmlLink);
        responseFromCal = event;
        var externEvent = new Event('myEvent');
        document.dispatchEvent(externEvent);

        return new Promise((resolve) => {
            console.log('event', event, res);
            resolve(event);
        });
    });
    const res1 = await responseFromCal;
    return res1;
}

function addMultiple(data) {
    // const events = [{
    //     'summary': 'multiple1',
    //     'location': 'coimbatore',
    //     'start': {
    //         'date': '2024-7-11',
    //         'timeZone': 'America/Los_Angeles'
    //     },
    //     'end': {
    //         'date': '2024-07-11',
    //         'timeZone': 'America/Los_Angeles'
    //     },
    // },
    // {
    //     'summary': 'multiple2',
    //     'location': 'coimbatore',
    //     'start': {
    //         'date': '2024-07-12',
    //         'timeZone': 'America/Los_Angeles'
    //     },
    //     'end': {
    //         'date': '2024-07-12',
    //         'timeZone': 'America/Los_Angeles'
    //     },
    // },
    // ];
    events = [...data];
    const batch = gapi.client.newBatch();
    events.map((r, j) => {
        batch.add(gapi.client.calendar.events.insert({
            'calendarId': 'primary',
            'resource': events[j]
        }))
    })
    batch.then(function (data) {
        responseFromCal = data;
        var externEvent = new Event('batchEventsAdded');
        document.dispatchEvent(externEvent);
        console.log('all jobs now dynamically done!!!')
    });
}
